import MedicalQuestionnaireApi from './medical-questionnaire.api';
import {
  BLOOD_TYPE,
  RH_FACTOR,
  FIELDS,
  BLOCKS_FIELDS_VIEW,
  LIST_RADIO_BOOLEAN
} from './medical-questionnaire.const';
import {
  getCharacteristics,
  getListFromObject
} from './medical-questionnaire.helpers';
import {
  getProfileFullName,
  getNameInitial,
  getAgeTitle
} from '@/helpers/patient';

class MedicalQuestionnaireService {
  constructor(client, $config) {
    this.api = new MedicalQuestionnaireApi(client, $config);
    this.idAnketa = null;
    this.isMan = true;
  }

  async getAnketaEdit(id) {
    const { anketa } = await this.api.getMedicalQuestionnaire(id);
    // const woman = anketa.medicalQuestionnaireForWomenResponse ?? {};
    this.idAnketa = anketa.id;

    /*
     * type: input - Обычный input
     * type: radio - Список чекбоксов
     * type: radio-with-input - Поле input, заполнение которого зависит от выбранно значения в поле isValueVisible
     * type: radio-with-radio - 2 списка чекбоксов, заполнение второго зависит от значения первого чекбокса
     * isOnlyRead - Поле предназначается только для чтения. Заполнять нельзя, не сохраняется на бэке
     * isBoolean - Если значение true/false. При сохранении не обрабатывает и отправляется либо выбранное значение, либо null
     * mainField - В поле записывается ключ поля, которое является основным, если оно false или пустое, то то поле так же делаем пустым
     */
    const anketaForm = [
      {
        title: 'pages.account.anketa.imt',
        fields: [
          {
            type: 'input',
            field: 'height',
            label: FIELDS.height.title,
            placeholder: FIELDS.height.placeholder,
            value: anketa.height,
            typeField: 'number',
            minValue: 1
          },
          {
            type: 'input',
            field: 'weight',
            label: FIELDS.weight.title,
            placeholder: FIELDS.weight.placeholder,
            value: anketa.weight,
            typeField: 'number',
            minValue: 1
          },
          {
            type: 'input',
            field: 'imt',
            label: 'pages.account.anketa.body-mass-index',
            placeholder: 'pages.account.anketa.body-mass-index-placeholder',
            value: anketa.bodyMassIndex,
            typeField: 'number',
            disabled: true,
            isOnlyRead: true
          }
        ]
      },
      {
        title: 'pages.account.anketa.blood-allergies',
        fields: [
          {
            type: 'radio',
            field: 'bloodType',
            title: FIELDS.bloodType.title,
            list: getListFromObject(BLOOD_TYPE),
            value: anketa.bloodType
          },
          {
            type: 'radio',
            field: 'rhFactor',
            title: FIELDS.rhFactor.title,
            list: getListFromObject(RH_FACTOR),
            value: anketa.rhFactor
          },
          {
            type: 'radio-with-input',
            field: 'hasAllergies',
            title: FIELDS.allergies.title,
            list: LIST_RADIO_BOOLEAN,
            value: anketa.hasAllergies,
            isValueVisible: [true],
            input: {
              value: anketa.allergies,
              typeField: 'text',
              field: 'allergies',
              label: 'pages.account.anketa.for-what-title',
              placeholder: 'common.general-words.write-separated-commas'
            }
          }
        ]
      },
      {
        title: 'pages.account.anketa.diseases-operations',
        fields: [
          {
            type: 'radio-with-input',
            field: 'hasChronicDiseases',
            title: FIELDS.chronicDiseases.title,
            list: LIST_RADIO_BOOLEAN,
            value: anketa.hasChronicDiseases,
            isValueVisible: [true],
            input: {
              value: anketa.chronicDiseases,
              typeField: 'text',
              field: 'chronicDiseases',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          },
          {
            type: 'radio-with-input',
            field: 'hasPermanentMedications',
            title: FIELDS.permanentMedications.title,
            list: LIST_RADIO_BOOLEAN,
            value: anketa.hasPermanentMedications,
            isValueVisible: [true],
            input: {
              value: anketa.permanentMedications,
              typeField: 'text',
              field: 'permanentMedications',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          },
          {
            type: 'radio-with-input',
            field: 'hasSurgeries',
            title: FIELDS.surgeries.title,
            list: LIST_RADIO_BOOLEAN,
            value: anketa.hasSurgeries,
            isValueVisible: [true],
            input: {
              value: anketa.surgeries,
              typeField: 'text',
              field: 'surgeries',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          }
        ]
      }
    ];

    return anketaForm;
  }

  async saveAnketa({ patientId, list }) {
    const params = {
      patientId,
      medicalQuestionnaireForWomenRequest: {}
    };

    list.forEach((section) => {
      section.fields
        .filter((i) => !i.isOnlyRead)
        .forEach((data) => {
          const { field, value, input, radio, type } = data;
          if (type === 'radio-with-input') {
            params[input.field] = value ? input?.value?.trim() || null : null;
            params[field] = typeof value === 'boolean' ? value : null;
          } else {
            params[field] = value ? input?.value || value : null;
            if (radio?.value) params[radio.field] = radio?.value;
          }
        });
    });

    if (this.idAnketa) await this.api.updateMedicalQuestionnaire(params);
    else await this.api.addMedicalQuestionnaire(params);
  }

  async getAnketaView(id) {
    const { anketa, patient } = await this.api.getMedicalQuestionnaire(id);
    this.idAnketa = anketa.id;
    return {
      listFields: this.getAnketaFieldsView(patient, anketa),
      patient: this.getPatient(patient, anketa)
    };
  }

  getAnketaFieldsView({ sex }, anketa) {
    const result = BLOCKS_FIELDS_VIEW.map((block) => {
      return block
        .map(({ field, isWoman = false, constValue }) => {
          /*
           * Исключаем поля преднозначенные для женщин
           */
          if (sex && isWoman) return false;
          let value = this.formatedFiledValue(field, anketa);
          if (constValue) value = constValue[value];

          if (typeof value === 'boolean')
            value = value ? 'common.yes-or-no.yes' : 'common.yes-or-no.no';

          return {
            id: field,
            label: FIELDS[field].title,
            value: value || '-',
            isHidden: anketa[field] === undefined
          };
        })
        .filter((i) => i && !i.isHidden);
    });
    return result;
  }

  getPatient(patient, anketa) {
    /*
     * Пол пациента используется для формирвоания данных при сохранении анкеты
     */
    this.isMan = patient.sex;
    const { bloodType, rhFactor } = anketa;
    return {
      ...patient,
      fullName: getProfileFullName(patient),
      initial: getNameInitial(patient, true),
      sexTitle: patient.sex ? 'Мужчина' : 'Женщина',
      age: getAgeTitle(patient),
      characteristics: getCharacteristics(anketa),
      blood: [BLOOD_TYPE[bloodType], RH_FACTOR[rhFactor]].filter((i) => i)
    };
  }

  formatedFiledValue(field, anketa) {
    const fileds = {
      allergies: {
        booleanFiled: 'hasAllergies'
      },
      surgeries: {
        booleanFiled: 'hasSurgeries'
      },
      permanentMedications: {
        booleanFiled: 'hasPermanentMedications'
      },
      chronicDiseases: {
        booleanFiled: 'hasChronicDiseases'
      }
    };

    if (typeof anketa[fileds[field].booleanFiled] === 'boolean') {
      if (anketa[field]?.trim()) return anketa[field];
      return anketa[fileds[field].booleanFiled] ? 'Да' : 'Нет';
    }

    return anketa[field];
  }
}
export default MedicalQuestionnaireService;
